import React from 'react'

import Layout from '../layout'
import Banner from '../components/banner'
import Upcoming from '../components/upcoming'
import HomeSection from '../components/homesection'
import NextEvents from '../components/nextevents'
import Featured from '../components/featured'

const IndexPage = () => {
  return (
    <Layout>
      <Banner />
      <Upcoming />
      <HomeSection />
      <NextEvents />
      <Featured />
    </Layout>
  )
}

export default IndexPage
