import React, {SVGAttributes} from 'react'

interface Props {
  color: string
  previous?: boolean
  next?: boolean
}

const Arrow: React.FC<Props & SVGAttributes<SVGElement>> = ({color, previous, next, ...props}) => (
  <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g transform={next && 'matrix(-1 0 0 1 40 0)'} stroke={color} strokeWidth={2} fill="none" fillRule="evenodd">
      <circle cx={20} cy={20} r={19} />
      <path d="M22.59 12.94l-7.3 7.3 7.3 7.41" />
    </g>
  </svg>
)

export default Arrow
