import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'

import Arrow from '../images/arrow'

import {colors} from '../helpers/constants'

const SCROLL_TRACK_MARGIN = 60

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 500px;
  @media (max-width: 600px) {
    height: 395px;
  }
`
const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -20px; /* to hide the browser scrollbar when below rules are not supported */
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
const ScrollButton = styled.button<{left?: boolean; right?: boolean}>`
  position: absolute;
  bottom: 0;
  ${(props) => (props.left ? 'left' : 'right')}: 0;
`
const ScrollTrack = styled.div`
  position: absolute;
  bottom: 25px;
  left: ${SCROLL_TRACK_MARGIN}px;
  right: ${SCROLL_TRACK_MARGIN}px;
  background: ${colors.grey};
  height: 2px;
`
const ScrollThumb = styled.div`
  position: relative;
  background: ${colors.purple};
  height: 2px;
`

const Scrollbars = ({children}) => {
  const scrollbars = useRef<HTMLDivElement>()
  const [position, setPosition] = useState('left')
  const [thumbX, setThumbX] = useState(0)
  const [thumbWidth, setThumbWidth] = useState(null)

  useEffect(() => {
    const {scrollWidth, clientWidth} = scrollbars.current
    const trackWidth = clientWidth - 2 * SCROLL_TRACK_MARGIN
    setThumbWidth((clientWidth / scrollWidth) * trackWidth)
  }, [])

  const handleScroll = () => {
    const {scrollLeft, scrollWidth, clientWidth} = scrollbars.current

    setThumbX((scrollLeft / (scrollWidth - clientWidth)) * (clientWidth - 2 * SCROLL_TRACK_MARGIN - thumbWidth))

    if (scrollLeft === 0) {
      if (position !== 'left') {
        setPosition('left')
      }
    } else if (scrollLeft === scrollWidth - clientWidth) {
      if (position !== 'right') {
        setPosition('right')
      }
    } else {
      if (position !== null) {
        setPosition(null)
      }
    }
  }

  const scroll = (left: number) => {
    if (typeof scrollbars.current.scroll === 'function') {
      try {
        scrollbars.current.scroll({left, behavior: 'smooth'})
      } catch (e) {
        scrollbars.current.scrollTo(left, 0)
      }
    } else {
      // older browsers
      scrollbars.current.scrollLeft = left
    }
  }

  const handleLeft = () => {
    scroll(0)
  }
  const handleRight = () => {
    const {scrollWidth, clientWidth} = scrollbars.current
    scroll(scrollWidth - clientWidth)
  }

  return (
    <Container>
      <Content onScroll={handleScroll} ref={scrollbars}>
        {children}
      </Content>
      <ScrollTrack>
        <ScrollThumb style={{width: thumbWidth, transform: `translateX(${thumbX}px)`}} />
      </ScrollTrack>
      <ScrollButton left onClick={handleLeft} aria-label="Début">
        <Arrow previous color={position === 'left' ? colors.white : colors.grey} />
      </ScrollButton>
      <ScrollButton right onClick={handleRight} aria-label="Fin">
        <Arrow next color={position === 'right' ? colors.white : colors.grey} />
      </ScrollButton>
    </Container>
  )
}

export default Scrollbars
