import {BikiniAd} from '../types/bikini'

import React, {useState, useEffect, useRef, CSSProperties} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'

import Img from './image'
import UniversalLink from './link'

import {currentTimestamp} from '../helpers/date'

const WIDTH = 320
const BREAKPOINT = WIDTH + 20 // page padding + scrollbar

const DURATION = 5000 // 5 seconds for each ad //TODO: make configurable?

const Section = styled.section`
  overflow: hidden;
  width: ${WIDTH}px;
  @media (max-width: ${BREAKPOINT}px) {
    width: 100%;
  }
  @media (max-width: 950px) {
    margin: 23px auto 0;
  }
`

const Link = styled(UniversalLink)`
  display: inline-block;
  vertical-align: top;
  width: ${WIDTH}px;
`

// for GIF fallback:
const Image = styled(Img)`
  width: 100%;
`

// we call the file "pub" instead of "ads" to prevent from triggering adblockers

const Ads = () => {
  const [index, setIndex] = useState(0)

  const {
    allBikiniAd: {edges},
  } = useStaticQuery(graphql`
    query {
      allBikiniAd {
        edges {
          node {
            id
            start
            end
            order
            image {
              childImageSharp {
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL # fallback for GIFs
            }
            url
            name
            newTab
          }
        }
      }
    }
  `)

  const now = currentTimestamp()
  const ads: BikiniAd[] = edges.map(({node}) => node).filter(({start, end}) => start < now && end > now)

  if (!ads.length) {
    // no active ad
    return null
  }

  ads.sort((left: BikiniAd, right: BikiniAd) => {
    // sort by order and then by end date, having the soonest ones first:
    if (left.order === right.order) {
      return left.end > right.end ? 1 : -1
    }
    return left.order > right.order ? 2 : -2
  })

  const ref = useRef<HTMLElement>()
  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(ref.current.offsetWidth)
    const timeout = setTimeout(() => {
      setIndex(index === ads.length - 1 ? 0 : index + 1)
    }, DURATION)
    return () => {
      clearTimeout(timeout)
    }
  }, [index])

  const style: CSSProperties = {
    width: ads.length * width,
    transform: `translateX(${-(index * width)}px)`,
  }
  if (index > 0) {
    style.transition = 'transform 0.3s'
  }
  let linkStyle: CSSProperties
  if (width < BREAKPOINT) {
    linkStyle = {
      width,
    }
  }

  return (
    <Section ref={ref}>
      <div style={style}>
        {ads.slice(0, index + 2).map(({id, image, url, name, newTab}: BikiniAd) => (
          <Link key={id} url={url} style={linkStyle} target={newTab ? '_blank' : null} aria-label={name}>
            <Image src={image} alt={name} />
          </Link>
        ))}
      </div>
    </Section>
  )
}

export default Ads
