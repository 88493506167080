import {BikiniVideo} from '../types/bikini'

import React from 'react'

const Video = ({provider, id, type}: BikiniVideo) => {
  let src: string
  switch (provider) {
    case 'soundsgood':
      src = `//play.soundsgood.co/embed/${id}`
      break
    case 'youtube':
      // https://developers.google.com/youtube/iframe_api_reference
      // tslint:disable-next-line: prefer-conditional-expression
      if (id === '5uN3n-NjLHc') {
        src = `//www.youtube.com/embed/${id}` // https://support.google.com/youtube/thread/14097672?hl=en
      } else {
        src = `//youtube-iframe.netlify.com/?v=${id}` // temporary workaround
      }
      break
    case 'soundcloud':
      src = `//w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/${type}s/${id}&auto_play=false&hide_related=true&show_comments=false&show_reposts=false&show_teaser=false`
      break
    case 'vimeo':
      src = `//player.vimeo.com/video/${id}`
      break
    case 'html':
      return <div className="unknown" dangerouslySetInnerHTML={{__html: id}} />
    default:
      return null
  }
  return (
    <iframe
      title={provider}
      src={src}
      className={type}
      frameBorder={0}
      allowFullScreen={type === 'video'}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  )
}

export default Video
