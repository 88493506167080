import {BikiniEvent} from '../types/bikini'

import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'

import {Title} from './styled'
import EventLine from './eventline'

import {eventFilter} from '../helpers/date'
import {MOBILE_BREAKPOINT} from '../helpers/constants'
import {useClientKey} from '../helpers/hooks'

const Section = styled.section`
  margin: 50px 0 50px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20px 0 20px;
  }
`
const CenteredTitle = styled(Title)`
  text-align: center;
`

const NextEvents = () => {
  const {
    allBikiniEvent: {edges},
  } = useStaticQuery(graphql`
    query {
      # there are about 120 concerts in the future, but we need to display only #5 to 15 here.
      # given that the website won't be updated less often than once a week (probably more like daily),
      # and that there are usually no more than 10 concerts per week, we can safely limit to 30 (even 25 would probably work):
      allBikiniEvent(
        filter: {past: {eq: false}, featured: {eq: true}, status: {nin: ["cancelled", "postponed", "full"]}}
        sort: {fields: [date], order: ASC}
        limit: 30
      ) {
        edges {
          node {
            id
            path
            date
            end
            unveilDate
            title
            status
            free
            ticketUrl
            password
            passwordLimitDate
            passwordPrompt
          }
        }
      }
    }
  `)

  const events = edges
    .map(({node}) => node)
    .filter(eventFilter) // TODO: live unveil
    .slice(0, 15)

  const key = useClientKey()

  return (
    <Section>
      <CenteredTitle>Événements à venir</CenteredTitle>
      {events.map((event: BikiniEvent) => (
        <EventLine key={key(event.id)} event={event} />
      ))}
    </Section>
  )
}

export default NextEvents
