import {BikiniNews, BikiniSection} from '../types/bikini'

import React, {useState, ReactElement} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import styled, {css} from 'styled-components'

import Ads from './pub'
import Down from '../images/down'
import Link from './link'

import {ddmmyy, currentTimestamp} from '../helpers/date'
import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {button} from '../helpers/style'
import Video from './video'

const COLUMN_BREAKPOINT = 950
const MENU_HEIGHT = 53

const Section = styled.section`
  margin: 80px 0;
  display: flex;
  @media (max-width: ${COLUMN_BREAKPOINT}px) {
    display: block;
    margin: 30px 0;
  }
`
const Dropdown = styled.button<{open: boolean}>`
  background: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + 20px);
  margin: 0 -10px 2px;
  padding: 12px 10px;
  text-align: left;
  svg {
    transition: ${(props) => (props.open ? 'transform 0.3s' : 'none')};
    transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 0 20px;
    width: 100%;
    padding: 12px 20px;
  }
  @media (min-width: ${COLUMN_BREAKPOINT}px) {
    display: none;
  }
`
const Menu = styled.div<{open: boolean}>`
  border-top: 2px solid ${colors.white};
  width: 320px;
  @media (max-width: 1050px) {
    width: 250px;
  }
  @media (max-width: ${COLUMN_BREAKPOINT}px) {
    border: none;
    width: 100%;
    transition: ${(props) => (props.open ? 'height 0.3s' : 'none')};
    overflow: hidden;
    position: absolute;
    z-index: 2;
    background: ${colors.background};
    padding: 0 10px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 -10px;
  }
`
const Tab = styled.button<{current: boolean}>`
  display: block;
  text-align: left;
  padding: 15px 0;
  width: 100%;
  height: 70px;
  border-bottom: 2px solid ${colors.white};
  @media (max-width: ${COLUMN_BREAKPOINT}px) {
    border: none;
    display: ${(props) => (props.current ? 'none' : 'block')};
    height: 45px;
    margin-top: 5px;
  }
`
const TabTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`
const TabText = styled.div<{current: boolean}>`
  font-size: 1.85rem;
  font-weight: bold;
  vertical-align: top;
  overflow: hidden;
  transition-property: height, color;
  transition-duration: 0.1s;
  height: ${(props) => (props.current ? 32 : 25)}px;
  color: ${(props) => (props.current ? colors.primary : colors.grey)};
  @media (min-width: ${COLUMN_BREAKPOINT}px) {
    :hover {
      height: 32px;
      /* color: ${colors.primary}; */
    }
  }
  @media (max-width: ${COLUMN_BREAKPOINT}px) {
    font-size: 1.25rem;
    height: 16px;
  }
`

const Content = styled.div`
  flex: 1;
  margin: 0 20px;
  min-height: 500px;
  @media (max-width: ${COLUMN_BREAKPOINT}px) {
    margin: 0;
    min-height: 0;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 -10px;
  }
`

const sectionCss = css`
  background: ${colors.white};
  padding: 15px 20px;
  margin-bottom: 20px;
  line-height: 1.2rem;
  h1,
  h2 {
    font-weight: bold;
    line-height: 1.5rem;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.85rem;
    padding: 15px 10px;
    margin-bottom: 2px;
    h1,
    h2 {
      font-size: 1rem;
    }
  }
`
const ArticleLink = styled(Link)`
  display: block;
  ${sectionCss}
  iframe {
    width: 100%;
    height: 340px;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      height: 220px;
    }
  }
`
const Article = styled.section`
  ${sectionCss}
`
const ArticleDate = styled.div`
  font-weight: 600;
  color: ${colors.secondary};
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 10px;
  }
`
const ArticleText = styled.p`
  white-space: pre-line;
`
const ActionButton = styled.span`
  display: inline-block;
  ${button}
  margin: 10px 0 5px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.85rem;
    padding: 8px 18px;
    margin: 5px 0;
  }
`

const HomeSection = () => {
  const [tab, setTab] = useState(0)
  const [openMenu, setOpenMenu] = useState(false)

  const handleTab = (index: number) => () => {
    setTab(index)
    setOpenMenu(false)
  }
  const handleMenu = () => setOpenMenu(!openMenu)

  const {allBikiniNews, allBikiniSection} = useStaticQuery(graphql`
    query {
      allBikiniNews(sort: {fields: [date], order: DESC}, limit: 4) {
        edges {
          node {
            id
            url
            title
            summary
            videos {
              provider
              id
              type
            }
            date
            end
          }
        }
      }
      allBikiniSection(filter: {page: {eq: "accueil"}}, sort: {fields: [order], order: ASC}) {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  `)

  const sections = [{title: 'Actus'}].concat(allBikiniSection.edges.map(({node}) => node))

  const section = sections[tab]
  let content: ReactElement

  if (tab === 0) {
    const now = currentTimestamp()

    content = allBikiniNews.edges
      .map(({node}) => node)
      .filter((article: BikiniNews) => article.end > now)
      .slice(0, 2)
      .map((article: BikiniNews) => (
        <ArticleLink key={article.id} url={article.url}>
          <h2>{article.title}</h2>
          <ArticleDate>{ddmmyy(article.date)}</ArticleDate>
          <ArticleText>{article.summary}</ArticleText>
          {article.videos.map((video, index) => (
            <Video key={index} {...video} />
          ))}
          <ActionButton>En savoir plus</ActionButton>
        </ArticleLink>
      ))
  } else {
    content = <Article dangerouslySetInnerHTML={{__html: (section as BikiniSection).content}} /> // TODO: handle images
  }

  return (
    <Section>
      <Dropdown onClick={handleMenu} open={openMenu}>
        <TabTitle>{section.title}</TabTitle>
        <Down />
      </Dropdown>
      <Menu open={openMenu} style={{height: openMenu ? (sections.length - 1) * MENU_HEIGHT : 0}}>
        {sections.map(({title}, index) => (
          <Tab key={index} current={index === tab} onClick={handleTab(index)}>
            <TabText current={index === tab}>{title}</TabText>
          </Tab>
        ))}
      </Menu>
      <Content>{content}</Content>
      <Ads />
    </Section>
  )
}

export default HomeSection
