import {BikiniEvent} from '../types/bikini'

import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'

import Scrollbars from '../components/scrollbars'
import Img from '../components/image'
import BookButton from './bookbutton'

import {available} from '../helpers/text'
import {dayMonth, timeFormat, eventFilter} from '../helpers/date'
import {colors} from '../helpers/constants'
import {ellipsis} from '../helpers/style'
import {useClientKey} from '../helpers/hooks'

const Section = styled.section``
const FirstEvents = styled.div`
  display: flex;
`
const Event = styled.div`
  margin-right: 20px;
  text-align: right;
  width: 320px;
  @media (max-width: 600px) {
    width: 220px;
    font-size: 0.7rem;
  }
`
const EventLabel = styled(BookButton)<{available: boolean}>`
  display: block;
  text-align: center;
  color: ${colors.primary};
  padding: 10px;
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  background: ${(props) => (props.available ? colors.white : colors.red)};
`
const EventTitle = styled.h1`
  margin: 10px 0 0;
  font-size: 1.25em;
  ${ellipsis}
`
const EventDate = styled.div`
  margin: 0.2rem 0;
  font-size: 1.25em;
`
const EventInfos = styled.p`
  font-size: 0.95rem;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 1.25em;
  }
`
const Image = styled(Img)`
  width: 320px;
  height: 320px;
  @media (max-width: 600px) {
    width: 220px;
    height: 220px;
  }
`

const Upcoming = () => {
  const {
    allBikiniEvent: {edges},
  } = useStaticQuery(graphql`
    query {
      # there are about 120 concerts in the future, but we need to display only the next 5 ones here.
      # given that the website won't be updated less often than once a week (probably more like daily),
      # and that there are usually no more than 10 concerts per week, we can safely limit to 20 (even 15 would probably work):
      allBikiniEvent(
        filter: {past: {eq: false}, featured: {eq: true}, status: {nin: ["cancelled", "postponed", "full"]}}
        sort: {fields: [date], order: ASC}
        limit: 20
      ) {
        edges {
          node {
            id
            path
            date
            end
            unveilDate
            title
            status
            ticketUrl
            free
            password
            passwordLimitDate
            passwordPrompt
            image {
              childImageSharp {
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const events = edges
    .map(({node}) => node)
    .filter(eventFilter) // TODO: live unveil
    .slice(0, 5)

  const key = useClientKey()

  return (
    <Section>
      <Scrollbars>
        <FirstEvents>
          {events.map((event: BikiniEvent) => {
            const {id, path, title, image, date, end} = event
            return (
              <Event key={key(id)} title={title}>
                <Link to={path} aria-label={title}>
                  <Image src={image} backgroundColor={colors.white} />
                </Link>
                <EventLabel event={event} available={available(event)} />
                <Link to={path}>
                  <EventTitle>{title}</EventTitle>
                  <EventDate>{dayMonth(date, end)}</EventDate>
                  <EventInfos>{timeFormat(date)}</EventInfos>
                </Link>
              </Event>
            )
          })}
        </FirstEvents>
      </Scrollbars>
    </Section>
  )
}

export default Upcoming
