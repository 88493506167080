import {BikiniFeatured} from '../types/bikini'

import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'

import Img from './image'
import UniversalLink from './link'
import Tags from './tags'

import {MOBILE_BREAKPOINT, colors} from '../helpers/constants'
import {currentTimestamp} from '../helpers/date'
import {button} from '../helpers/style'

const BREAKPOINT = 900

const Link = styled(UniversalLink)`
  display: flex;
  align-items: stretch;
  margin-bottom: 50px;
  min-height: 250px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`
const Image = styled(Img)`
  flex: 660;
  max-height: 382px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-height: 174px;
  }
`
const Content = styled.div`
  position: relative;
  background: ${colors.white};
  flex: 320;
  margin: 0 0 0 20px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
  }
`
const EventHead = styled.div`
  font-size: 1.1rem;
  padding: 10px 25px;
  border-bottom: 3px solid ${colors.background};
  @media (max-width: ${BREAKPOINT}px) {
    padding: 10px 20px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 9px 10px;
  }
`
const EventDate = styled.span`
  font-weight: 600;
  @media (max-width: ${BREAKPOINT}px) {
    font-size: 0.9rem;
  }
`
const Title = styled.h1`
  padding: 0 25px;
  margin: 20px 0 0;
  @media (max-width: ${BREAKPOINT}px) {
    padding: 0 20px;
    font-size: 1.5rem;
    margin: 10px 0 0;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 10px;
    font-size: 0.95rem;
  }
`
const Infos = styled.div`
  padding: 13px 25px;
  line-height: 1.5rem;
  font-size: 0.9rem;
  white-space: pre-line;
  @media (max-width: ${BREAKPOINT}px) {
    padding: 10px 20px;
    line-height: 1.2rem;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.75rem;
    padding: 7px 10px;
  }
`
const Labels = styled.div`
  margin: 10px 10px 20px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    position: absolute;
    left: 50%;
    bottom: 30px;
    margin-bottom: 10px;
  }
`
const ActionButton = styled.span`
  ${button}
  font-weight: 600;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 8px 18px;
  }
  @media (max-width: ${BREAKPOINT}px) {
    font-size: 0.75rem;
  }
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    position: relative;
    left: -50%;
  }
`

const Featured = () => {
  const {
    allBikiniFeatured: {edges},
  } = useStaticQuery(graphql`
    query {
      allBikiniFeatured {
        edges {
          node {
            id
            start
            end
            image {
              childImageSharp {
                fluid(maxWidth: 660) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            url
            date
            title
            content
            _tags
          }
        }
      }
    }
  `)

  const now = currentTimestamp()
  const ads: BikiniFeatured[] = edges.map(({node}) => node).filter(({start, end}) => start < now && end > now)

  if (!ads.length) {
    // no active ad
    return null
  }

  ads.sort((left: BikiniFeatured, right: BikiniFeatured) => (left.end > right.end ? 1 : -1))

  return (
    <>
      {ads.map(({id, image, url, date, title, content, _tags}) => (
        <Link url={url} key={id} aria-label={title}>
          <Image src={image} />
          <Content>
            <EventHead>
              <Tags tags={_tags} />
              <EventDate>{date}</EventDate>
            </EventHead>
            <Title>{title}</Title>
            <Infos>{content}</Infos>
            <Labels>
              <ActionButton>Réserver</ActionButton>
            </Labels>
          </Content>
        </Link>
      ))}
    </>
  )
}

export default Featured
